<template>
    <Modal :name="'payment'" :is-open="true" :show-close-btn="false" v-if="!requestError">
        <div v-if="success === true">
            <div class="d-flex justify-content-center" style="width: 400px">
                <div>
                    <CheckCircle/>
                </div>
            </div>
            <div class="success-caption mt-3 mb-5">{{ $t('payment-successful-caption') }}</div>
        </div>
        <div v-if="success === false">
            <div class="d-flex justify-content-center" style="width: 400px">
                <div>
                    <ErrorIcon/>
                </div>
            </div>
            <div class="fail-caption mt-3 mb-5">{{ errorMessage }}</div>
        </div>
        <div v-if="success === null">
            <div class="d-flex justify-content-center" style="width: 400px">
                <div>
                    <LoadingCircle/>
                </div>
            </div>
            <div class="caption mt-3 mb-5">{{ $t('processing-payment-caption') }}</div>
        </div>
        <Button
                v-if="success !== null"
                :size="'medium'"
                :theme="'solid-green'"
                :text="$t('back-to-profile')"
                @click="$router.push({'name': $store.state.user.info.type === 'vendor' ? 'VendorDashboard' : 'AgencyDashboard'})"
        />
    </Modal>
</template>

<script>


import CheckCircle from "@/components/Common/Icons/CheckCircle.vue";
import Modal from "@/components/Modal/Modal";
import Button from "@/components/Common/Buttons/Button/Button";
import CrossIcon from "@/components/Common/Icons/OvenIcon.vue";
import LoadingCircle from "@/components/Common/Icons/LoadingCircle.vue";
import ErrorIcon from "@/components/Common/Icons/ErrorIcon.vue";

export default {
    components: {ErrorIcon, LoadingCircle, CrossIcon, Button, Modal, CheckCircle},
    data() {
        return {
            success: null,
            errorMessage: ''
        }
    },
    async mounted() {
        this.errorMessage = this.$t('payment-failed-caption');

        const orderId = this.$route.query.orderId;

        try {
            const response = await this.$store.dispatch("payment/checkCreditBuyStatus", {reference: orderId});
            console.log(response);
            if (response.hasOwnProperty('error') || !response.hasOwnProperty('status')) {
                throw new Error();
            }
            this.success = response.status === 'paid';
            await this.$store.dispatch("user/refreshAuthenticatedUser");
        } catch (err) {
            this.success = false;
            this.errorMessage = this.$t('system-error')
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";

.caption,
.success-caption,
.fail-caption {
    color: $color-grey-52;
    font-family: $font-bold;
    font-size: 18px;
    text-align: center;
}

.fail-caption {
    color: #dc3c3c;
}
.success-caption {
    color: #367a08;
}
</style>